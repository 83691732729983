import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio4.jpg'
import IMG2 from '../../assets/portfolio2.PNG'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Solar System Geeks Online Store',
    github: 'https://github.com/kido8013/Solar-System-Geeks-Online-Store',
    demo: 'https://github.com/kido8013/Solar-System-Geeks-Online-Store'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Data Analysis Projects',
    github: 'https://github.com/kido8013/data-analysis-projects',
    demo: 'https://public.tableau.com/app/profile/keaton.kido/vizzes'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Bug Wars',
    github: 'https://github.com/yuneKim/bug-wars-client',
    demo: 'https://yunekim.github.io/bug-wars-client/#/'
  },
]

const portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
      {
        data.map(({id, image, title, github, demo}) => {
          return (   
          <article key={id} className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={image} alt={title} />
          </div>
          <h3>{title}</h3>
          <div className='portfolio__item-cta'>
          <a href={github} className='btn' target='_blank'>Github</a>
          <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        )
        })
      }
        
      </div>
    </section>
  )
}

export default portfolio