import React from 'react'
import { FaLinkedin } from "react-icons/fa";
import { FaGithubSquare } from "react-icons/fa";


const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="http://linkedin.com/in/keatonkido"><FaLinkedin/>
</a>
        <a href="http://github.com/kido8013"><FaGithubSquare/>
</a>
    </div>
  )
}

export default HeaderSocials