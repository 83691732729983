import React from 'react'
import './services.css'
import { IoMdCheckmark } from "react-icons/io";

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service__list'>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>User-Centric Design: Prioritizing user needs and creating intuitive, user-friendly interfaces.</p>
            </li>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>User Research: Conducting user interviews and usability testing to inform design decisions.</p>
            </li>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>Responsive Design: Ensuring seamless user experiences across various devices and screen sizes.</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className='service'>
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>Full-Stack Development: Expertise in both front-end (HTML, CSS, JavaScript) and back-end (Node.js, Express, MongoDB) technologies.</p>
            </li>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>Database Management: Designing and optimizing databases for efficient data storage and retrieval.</p>
            </li>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>API Integration: Connecting web applications to external services and APIs for enhanced functionality.</p>
            </li>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>Version Control: Proficient in using Git for collaborative development and version control.</p>
            </li>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>Agile Methodology: Experience in working with Agile methodologies for project management and development.</p>
            </li>
          </ul>
          {/* END OF WEB DEV */}
        </article> <article className='service'>
          <div className="service__head">
            <h3>Data Analysis</h3>
          </div>
          <ul className='service__list'>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>Data Cleaning and Preparation: Expertise in cleaning, transforming, and preparing datasets for analysis using tools like SQL, and R.</p>
            </li>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>Data Visualization: Proficient in creating insightful visualizations and dashboards using Tableau, ggplot2, Excel and other visualization libraries to communicate findings effectively.</p>
            </li>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>Data Wrangling: Ability to handle large and complex datasets, perform data wrangling, and extract meaningful insights using R, Python, and SQL.</p>
            </li>
            <li>
              <IoMdCheckmark className='service__list-icon' />
              <p>SQL Proficiency: Advanced knowledge of SQL for querying databases, performing joins, and aggregating data for analysis.</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services